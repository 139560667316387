import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ed57624"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "sceneSpecimenResultSave" }
const _hoisted_2 = { class: "table-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!
  const _component_van_uploader = _resolveComponent("van-uploader")!
  const _component_van_col = _resolveComponent("van-col")!
  const _component_van_row = _resolveComponent("van-row")!
  const _component_van_form = _resolveComponent("van-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_cell_group, null, {
      default: _withCtx(() => [
        _createVNode(_component_van_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "样本信息"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.specimenForm.name) = $event)),
                  label: "样本名称",
                  placeholder: "样本名称",
                  "input-align": "right",
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.specimenForm.code) = $event)),
                  label: "样本编号",
                  placeholder: "样本编号",
                  "input-align": "right",
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.address,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.specimenForm.address) = $event)),
                  type: "textarea",
                  label: "采样地址",
                  placeholder: "采样地址",
                  "input-align": "right",
                  readonly: ""
                }, null, 8, ["modelValue"]),
                _createVNode(_component_van_field, {
                  modelValue: _ctx.specimenForm.collectTime,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.specimenForm.collectTime) = $event)),
                  readonly: "",
                  label: "采样时间",
                  placeholder: "点击选择时间",
                  "input-align": "right"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "样本图片"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_van_uploader, {
                  modelValue: _ctx.fileList,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.fileList) = $event)),
                  multiple: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_van_cell_group, {
              inset: "",
              title: "项目信息"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_van_row, { class: "th-row" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 项目名称 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 结果 ")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_van_col, { span: 24/3 }, {
                        default: _withCtx(() => [
                          _createTextVNode(" 单位 ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.specimenForm.specimenModuleItemList, (item, index) => {
                    return (_openBlock(), _createBlock(_component_van_row, {
                      class: "tb-row",
                      key: index,
                      style: _normalizeStyle({background:index % 2 === 0 ?'#fff':'#F4F9FE'})
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_van_field, {
                              class: "checkResultClazz",
                              modelValue: item.checkResult,
                              "onUpdate:modelValue": ($event: any) => ((item.checkResult) = $event),
                              placeholder: "请输入结果",
                              readonly: ""
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_van_col, { span: 24/3 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.unit), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["style"]))
                  }), 128))
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}